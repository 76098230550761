import Aldersgrenser from "@/public/icons/Aldersgrenser.svg";
import Babykino from "@/public/icons/Babykino.svg";
import Bamse from "@/public/icons/Bamse.svg";
import Barnevogn from "@/public/icons/Barnevogn.svg";
import Briller3D from "@/public/icons/Briller3D.svg";
import ChairHjerte from "@/public/icons/ChairHjerte.svg";
import ChairMark1 from "@/public/icons/ChairMark1.svg";
import Dykker from "@/public/icons/Dykker.svg";
import Filmklubb from "@/public/icons/Filmklubb.svg";
import Filmramme from "@/public/icons/Filmramme.svg";
import Gavebillett from "@/public/icons/Gavebillett.svg";
import HandBillettHjerte from "@/public/icons/HandBillettHjerte.svg";
import Kamera from "@/public/icons/Kamera.svg";
import Kino from "@/public/icons/Kino.svg";
import Kinobursdag from "@/public/icons/Kinobursdag.svg";
import KinoIcon from "@/public/icons/KinoIcon.svg";
import Kiosk from "@/public/icons/Kiosk.svg";
import Kommer from "@/public/icons/Kommer.svg";
import Kultur from "@/public/icons/Kultur.svg";
import PalmeFestival from "@/public/icons/PalmeFestival.svg";
import PalmeLeft from "@/public/icons/PalmeLeft.svg";
import PalmeRight from "@/public/icons/PalmeRight.svg";
import Seniorkino from "@/public/icons/Seniorkino.svg";
import Strikkekino from "@/public/icons/Strikkekino.svg";
import VelgFilm from "@/public/icons/VelgFilm.svg";

// custom icons:
import Filmperler from "@/public/icons/custom/Filmperler.svg";




//#region [Other]
const ICONS: Record<string, JSX.Element> = {
	"Aldersgrenser": <Aldersgrenser />,
	"Babykino": <Babykino />,
	"Bamse": <Bamse />,
	"Barnevogn": <Barnevogn />,
	"Briller3D": <Briller3D />,
	"ChairHjerte": <ChairHjerte />,
	"ChairMark1": <ChairMark1 />,
	"Dykker": <Dykker />,
	"Filmklubb": <Filmklubb />,
	"Filmramme": <Filmramme />,
	"Gavebillett": <Gavebillett />,
	"HandBillettHjerte": <HandBillettHjerte />,
	"Kamera": <Kamera />,
	"Kino": <Kino />,
	"Kinobursdag": <Kinobursdag />,
	"KinoIcon": <KinoIcon />,
	"Kiosk": <Kiosk />,
	"Kommer": <Kommer />,
	"Kultur": <Kultur />,
	"PalmeFestival": <PalmeFestival />,
	"PalmeLeft": <PalmeLeft />,
	"PalmeRight": <PalmeRight />,
	"Seniorkino": <Seniorkino />,
	"Strikkekino": <Strikkekino />,
	"VelgFilm": <VelgFilm />,

};

export default ICONS;
//#endregion

//#region [Other]
export const CUSTOM_ICONS: Record<string, JSX.Element> = {
	"Filmperler": <Filmperler />
};
//#endregion