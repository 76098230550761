import { Filmomtale } from "@filmweb/sanity-types";
import { FileAsset, KeyedObject, PortableTextBlock, Reference, SanityDocument, Slug, TypedObject } from "@sanity/types";
import { IListExtLink } from "lib/types/Frontpage";
import FwPakkeImageDocument from "lib/types/FwPakkeImageDocument";
import Settings from "lib/types/Settings";
import UserSettings from "lib/types/UserSettings";

export interface FooterAppData {
	col1: PortableTextBlock[];
	col2: PortableTextBlock[];
	col3: PortableTextBlock[];
}

export interface Menu {
	dropDownMenu: IMenuItem[];
	buttonrow?: IButtonRowItem[];
	messageBanner?: IMessageBanner;
}

export interface IMenuItem extends TypedObject, KeyedObject {
	title: string;
	icon?: string;
	customIcon?: string;
	slug?: Slug;
	extPage?: string;
	page?: string;
}

export interface IButtonRowItem extends TypedObject, KeyedObject {
	title: string;
	slug?: Slug;
	extPage?: string;
	page?: string;
}

export interface IMessageBanner extends TypedObject, KeyedObject {
	overrideMessage?: string;
	article?: INewsArticle;
}

export interface ICommonAppData {
	menu: Menu;
	footer: FooterAppData;
	settings: Settings;
	userSettings?: UserSettings;
}

export interface ICommonProps {
	appData: ICommonAppData;
}

export interface IQueryResult<T> {
	preview: T;
	published: T;
}


// These are the possible values of _type properties of the interfaces defined in this namespace
export const DocumentType_IMAGE: string = "fwpakkeImageDocument";
export const DocumentType_MOVIE: string = "fwpakkeFilmomtale";
export const DocumentType_NEWS: string = "fwpakkeNewsDocument";
export const DocumentType_SPECIALSHOW: string = "fwpakkeSpecialshow";
export const DocumentType_CULTURE: string = "fwpakkeKulturomtale";

// Singleton types
export const DocumentType_FRONTPAGE: string = "fwpakkeFrontpage";
export const DocumentType_FOOTER: string = "fwpakkeFooter";
export const DocumentType_MENUS: string = "fwpakkeMenus";
export const DocumentType_SETTINGS: string = "fwpakkeSettings";
export const DocumentType_USERSETTINGS: string = "fwpakkeUserEditableSettings";
export const DocumentType_UPCOMING: string = "fwpakkeUpcoming";
export const DocumentType_FILE: string = "downloadableFile";

export type { FwPakkeImageDocument, Settings };

export function isDocumentType(doc: SanityDocument, documentType: string) {
	return doc._type === documentType;
}

export interface IFileArticle extends SanityDocument {
	title: string;
	file: FileAsset;
}

export interface INewsArticle extends SanityDocument {
	title: string;
	slug: Slug;
	frontpageText?: PortableTextBlock[];
	ingress: PortableTextBlock[];
	bodyText: PortableTextBlock[];
	image: FwPakkeImageDocument | Reference; // may be the full image document or just it's reference
	movies: (IMovieArticle | ICultureArticle | Reference)[];
}

export interface IMovieArticle extends SanityDocument {
	title: string;
	titleOverride?: string;
	edi: Slug;
	premiereDate?: string;
	image: FwPakkeImageDocument | Reference; // may be the full image document or just it's reference
	oneliner?: string;
	frontpageText?: PortableTextBlock[];
	ingress?: PortableTextBlock[];
	bodyText?: PortableTextBlock[];
}

export interface ICultureArticle extends SanityDocument {
	title: string;
	titleOverride?: string;
	kulnr: Slug;
	frontpageText?: PortableTextBlock[];
	ingress: PortableTextBlock[];
	bodyText: PortableTextBlock[];
	image: FwPakkeImageDocument | Reference;
}

export interface ISpecialShowArticle extends SanityDocument {
	title: string;
	slug: Slug;
	frontpageText: PortableTextBlock[];
	ingress: PortableTextBlock[];
	bodyText: PortableTextBlock[];
	icon?: string;
	customIcon?: string;
	image: FwPakkeImageDocument | Reference;
	frontpageImage: FwPakkeImageDocument | Reference;
	movies: (IMovieArticle | ICultureArticle | Reference)[];
	showType: string;
}

export interface IProgramPage extends SanityDocument {
	topInfoText: PortableTextBlock[];
	showInfoText: boolean;
}

export interface IUpcomingPage extends SanityDocument {
	movies?: IMovieArticle[];
}

export function getUrl(document: (INewsArticle | IMovieArticle | ICultureArticle | Filmomtale | IMenuItem | IButtonRowItem | ISpecialShowArticle | IFileArticle | IListExtLink)): string | null {
	if (!document?._type) {
		throw new Error(`Not a valid document ${document?._type}`);
	}

	let url: string | null;

	switch (document._type) {
		case "filmomtale":
			const film = document as Filmomtale;
			url = `/film/${film.mainVersionEDI}`;
			break;
		case DocumentType_MOVIE:
			const movie = document as IMovieArticle;
			url = getFilmUrl(movie.edi);
			break;
		case DocumentType_NEWS:
			const news = document as INewsArticle;
			url = getNewsUrl(news.slug);
			break;
		case DocumentType_SPECIALSHOW:
			const specShow = document as ISpecialShowArticle;
			url = getSpecialShowUrl(specShow.slug);
			break;
		case DocumentType_FILE:
			const fileDoc = document as IFileArticle;
			url = getFileUrl(fileDoc.file);
			break;
		case "newsRef":
			const newsRef = document as IMenuItem;
			url = getNewsUrl(newsRef.slug!);
			break;
		case "specialShowRef":
			const specShowRef = document as IMenuItem;
			url = getSpecialShowUrl(specShowRef.slug!);
			break;
		case "intLink":
			const intLink = document as IMenuItem;
			url = intLink.page!;
			break;
		case "listExtLink":
			const listExtLink = document as IListExtLink;
			if( !listExtLink.extPage) {
				url = "";
			} else {
				url = listExtLink.extPage;
			}
		case "extLink":
			const extLink = document as IMenuItem;
			return extLink.extPage!;
			break;
		case DocumentType_CULTURE:
			const culture = document as ICultureArticle;
			url = getCultureUrl(culture.kulnr);
			break;
		default:
			throw new Error(`Unrecognized document type: ${document._type}`);
	}

	return url;
}

export function getUrlForDocType(documentType: string, slug: Slug | FileAsset): string | null {
	let url: string | null;

	switch (documentType) {
		case DocumentType_MOVIE:
			url = getFilmUrl(slug as Slug);
			break;
		case DocumentType_NEWS:
			url = getNewsUrl(slug as Slug);
			break;
		case DocumentType_CULTURE:
			url = getCultureUrl(slug as Slug);
			break;
		case DocumentType_SPECIALSHOW:
			url = getSpecialShowUrl(slug as Slug);
			break;
		case DocumentType_FILE:
			url = getFileUrl(slug as FileAsset);
			break;
		/*case DocumentType_NEWS_LIST:
			url = "/nyheter";
			break;*/
		case DocumentType_FRONTPAGE:
		case DocumentType_FOOTER:
		case DocumentType_MENUS:
			url = `/`;
			break;
		case DocumentType_UPCOMING:
			url = "/kommer";
			break;
		default:
			throw new Error(`Unrecognized document type: ${documentType}`);
	}

	return url;
}

export function getNewsUrl(slug: Slug): string | null {
	if (!slug?.current) {
		return null;
	}
	return `/informasjon/${slug.current}`;
}

export function getFilmUrlFromEdi(edi: string): string {
	return `/film/${edi}`;
}

export function getFilmUrl(slug: Slug): string | null {
	if (!slug?.current) {
		return null;
	}
	return `/film/${slug.current}`;
}

export function getCultureUrl(slug: Slug): string | null {
	if (!slug?.current) {
		return null;
	}
	return `/kulturprogram/${slug.current}`;
}

export function getSpecialShowUrl(slug: Slug) {
	return `/program/${slug?.current}`;
}

export function getFileUrl(file: FileAsset): string | null {
	if (!file?.url) {
		return null;
	}
	return file.url;
}

export function getImage(document: (INewsArticle | IMovieArticle | ICultureArticle)): FwPakkeImageDocument {
	if (!document?._type) {
		throw new Error(`Not a valid document ${document?._type}`);
	}

	let image: FwPakkeImageDocument;

	switch (document._type) {
		case DocumentType_MOVIE:
			const movie = document as IMovieArticle;
			image = movie.horizontalPoster as FwPakkeImageDocument;
			break;
		case DocumentType_NEWS:
			const news = document as INewsArticle;
			image = news.topImage as FwPakkeImageDocument;
			break;
		case DocumentType_CULTURE:
			const culture = document as ICultureArticle;
			image = culture.horizontalPoster as FwPakkeImageDocument;
			break;
		default:
			throw new Error(`Unrecognized document type: ${document._type}`);
	}

	return image;
}
